import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import AccentBox from "components/accentBox";
import BasicTable from "components/basicTable";
import Card from "components/card";
import { Link, graphql } from "gatsby";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "usb_type-c"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/usbtypec.png"}) { ...eyecatchImg },
    usbtypec_s: file(relativePath: { eq: "usbtypec/usbtypec.PNG"}) { ...smallImg },
    usb_type_A_B_C_s: file(relativePath: { eq: "usbtypec/usb_type_A_B_C.PNG"}) { ...smallImg },
    pd_chain: file(relativePath: { eq: "usbtypec/pd_chain.PNG"}) { ...normalImg },
    pc_display: file(relativePath: { eq: "usbtypec/pc_display.PNG"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`USB Type-Cとは?USB3.1、PD、Altモードなど周辺技術を解説`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="USB TypeCとは" mdxType="Image" />
    <p>{`USB Type-Cを用いてできることはデータ伝送に加え映像出力や急速充電など幅広い。`}</p>
    <p>{`しかし`}<strong parentName="p"><em parentName="strong">{`全てのUSB Type-Cがこれらの機能を使えるわけではなく、パソコン、モニタなどの接続機器とケーブルがそれぞれ対応して初めて各機能が有効`}</em></strong>{`となる。`}</p>
    <p>{`端子がリバーシブルで使いやすくなった半面、`}<strong parentName="p"><em parentName="strong">{`端子形状だけでは使える機能が分からず理解しにくくなったUSB Type-Cの機能、特徴を分かりやすく説明`}</em></strong>{`し、製品選びで迷わない知識の習得を目指す。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "USB-Type-Cのパソコン購入時の注意まとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#USB-Type-C%E3%81%AE%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E8%B3%BC%E5%85%A5%E6%99%82%E3%81%AE%E6%B3%A8%E6%84%8F%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "USB Type Cのパソコン購入時の注意まとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB Type-Cのパソコン購入時の注意まとめ`}</h2>
    <p>{`USB Type-Cは仕様が非常に難解であるため、パソコン購入時の注意に限定してまずは説明する。より深く理解したい人は２節以降も読んでほしい。`}</p>
    <p>{`Type-Cには大きく分けて３つの特徴的な機能が存在する。`}<em parentName="p">{`高速データ伝送機能`}</em>{`、`}<em parentName="p">{`映像出力機能`}</em>{`、`}<em parentName="p">{`急速充電機能`}</em>{`である。その他電力供給は従来のUSBと同じく存在するため、無線マウスの子機をつなぐ、ヘッドセットを繋ぐ等は基本的に可能。`}</p>
    <p><em parentName="p">{`高速データ伝送機能`}</em>{`は従来のUSBと同じで、USBメモリや外部メモリとデータの受け渡しができる機能であり更に高速化されたというだけである。速度によりUSB 3.2、4のように分かれるが、これは市販のパソコンに付属のUSB Type-Cの全てが対応しているのであまり気にしなくてもよい。`}</p>
    <p><em parentName="p">{`急速充電機能`}</em>{`はPowerDeliveryとも呼ばれ、このUSB端子に電源を挿すことでノートパソコンの急速充電が行える。およそ２時間もあれば満充電に持っていける。`}</p>
    <p><em parentName="p">{`映像出力機能`}</em>{`はHDMIケーブルのように外部モニターに映像を表示する機能である。通常4K出力はサポートされる。`}</p>
    <p>{`また、`}<em parentName="p">{`Thunderbolt`}</em>{`という規格があり、`}<strong parentName="p"><em parentName="strong">{`上記の３つの機能をすべて満たすType-C端子がThunderbolt端子を名乗ることができる。`}</em></strong>{`Thunderboltは全部入りと考えればよい。`}</p>
    <p>{`それぞれの`}<strong parentName="p"><em parentName="strong">{`USB Type-C端子がどの機能を備えているかは仕様書や製品説明のページに必ず記載されている`}</em></strong>{`ので確認してほしい。一般的な表記例は下記である。`}</p>
    <BasicTable mdxType="BasicTable">
  <tr>
      <th>機能</th>
      <th>一般的な表記例</th>
      <th>説明</th>
  </tr>
  <tr>
      <td>データ転送</td>
      <td>
          USB 4 <br />
          USB 3.2 Gen2<br />
          USB 3.2 Gen1<br />
      </td>
      <td>データ転送速度が規定される。<br />「USB 4」は40Gbps、「USB 3.2 Gen2」は10Gbps「USB 3.2 Gen1」は5Gbpsでデータ伝送可能</td>
  </tr>
  <tr>
      <td>映像出力</td>
      <td>
          DisplayPort 1.4対応<br />
          USB-C (DP Alt Mode対応)
      </td>
      <td>映像出力が可能な場合、「DisplayPort対応」「DP Alt Mode対応」などの表記が見られる。具体的なバージョン（1.2や1.4など）が示されることもある。</td>
  </tr>
  <tr>
      <td>急速充電機能</td>
      <td>
          USB Power Delivery対応<br />
          PD対応<br />
          65W対応
      </td>
      <td>急速充電の機能。電源代わりにも使える。最大65Wなどの出力ワット数が書かれていることがあり、本体を２時間程度で満充電にすることが可能。</td>
  </tr>
  <tr>
      <td>Thunderbolt</td>
      <td>
          Thunderbolt 4対応
      </td>
      <td>Thunderbolt対応が記載されていれば高いデータ転送速度、映像出力、Power Deliveryの3つの機能に対応していることが保証される。</td>
  </tr>
    </BasicTable>
    <h3 {...{
      "id": "Thunderboltのメリット",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Thunderbolt%E3%81%AE%E3%83%A1%E3%83%AA%E3%83%83%E3%83%88",
        "aria-label": "Thunderboltのメリット permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Thunderboltのメリット`}</h3>
    <p>{`USB Type-Cのすべての機能に対応できるThunderbolt対応パソコンの最大のメリットは`}<strong parentName="p"><em parentName="strong">{`外部モニターや周辺機器との接続が圧倒的に楽になること`}</em></strong>{`である。`}</p>
    <p>{`Type-Cモニターとつなげば`}<strong parentName="p"><em parentName="strong">{`Thunderbolt対応ケーブル１本でパソコンへの給電と映像出力が同時に行うことができ`}</em></strong>{`、電源コードは不要となる。`}</p>
    <p>{`あるいは`}<a parentName="p" {...{
        "href": "https://amzn.to/4fpM8JG",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`ドッキングステーション`}</a>{`と呼ばれるHDMI、DisplayPort、USB、オーディオジャック、カードスロットなど複数のポートや電力供給機能を備えた製品とノートパソコンをつなげることで、`}<strong parentName="p"><em parentName="strong">{`ノートパソコンをデスクトップと近い環境で`}</em></strong>{`で使うことができる。`}</p>
    <p>{`モニター、プリンタ、無線マウス、キーボード、外付けストレージ、外付けブルーレイ再生機、USB扇風機などパソコンの周辺機器は数知れないが、ドッキングステーションにあらかじめ使用するものを挿しておき、あとは`}<strong parentName="p"><em parentName="strong">{`使いたい時にノートパソコンにケーブルを一本指せばワーキングステーションの完成`}</em></strong>{`といった塩梅である。`}</p>
    <p>{`これら機能は映像出力やPower Deliveryの電力供給機能をフル活用しているため、Thunderboltに対応していなければ実現できない。`}</p>
    <p>{`このため`}<strong parentName="p"><em parentName="strong">{`外部モニターを使うことを前提とし、周辺機器を拡張し、ノートパソコンをデスクトップのように使いたい人にThunderboltは向いている`}</em></strong>{`といえる。`}</p>
    <p>{`逆に外部モニターをはじめ周辺機器をいろいろつなぐ予定が無いという人は積極的にコスト削減のために落として良い機能である。`}</p>
    <h3 {...{
      "id": "購入検討時に考えるべきこと",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E8%B3%BC%E5%85%A5%E6%A4%9C%E8%A8%8E%E6%99%82%E3%81%AB%E8%80%83%E3%81%88%E3%82%8B%E3%81%B9%E3%81%8D%E3%81%93%E3%81%A8",
        "aria-label": "購入検討時に考えるべきこと permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`購入検討時に考えるべきこと`}</h3>
    <p>{`基本的に販売されているPCは高速データ伝送のみ対応のものか、Thunderbolt対応のものかにおおよそ２分される。中にはThunderboltではないが映像出力は保証など様々な条件があるが例外的なものである。`}</p>
    <p>{`よって`}<strong parentName="p"><em parentName="strong">{`見るべきポイントはType-CがThunderbolt対応か否かだけ`}</em></strong>{`である。まずは仕様書で対応を確認し、上述したメリットで活用の価値があると踏んだならば搭載し、あまりメリットが無いと感じたならば他のパソコンを選べばよいだろう。`}</p>
    <p>{`Thunderbolt搭載パソコンで安価なモデルはなかなか見つけられないので、トータルの予算も考慮して検討を行おう。`}</p>
    <h2 {...{
      "id": "USB-Type-Cはどんな仕様か？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#USB-Type-C%E3%81%AF%E3%81%A9%E3%82%93%E3%81%AA%E4%BB%95%E6%A7%98%E3%81%8B%EF%BC%9F",
        "aria-label": "USB Type Cはどんな仕様か？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB Type-Cはどんな仕様か？`}</h2>
    <p>{`ここからはじっくりType-Cについて理解したい人向けの記事となる。まずはUSB Type-Cの仕様についてざっくりと抑えておこう。`}</p>
    <h3 {...{
      "id": "USB-Type-Cは形状及びピンの役割を定義した規格",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#USB-Type-C%E3%81%AF%E5%BD%A2%E7%8A%B6%E5%8F%8A%E3%81%B3%E3%83%94%E3%83%B3%E3%81%AE%E5%BD%B9%E5%89%B2%E3%82%92%E5%AE%9A%E7%BE%A9%E3%81%97%E3%81%9F%E8%A6%8F%E6%A0%BC",
        "aria-label": "USB Type Cは形状及びピンの役割を定義した規格 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB Type-Cは形状及びピンの役割を定義した規格`}</h3>
    <Image {...props} name="usbtypec_s" alt="USB TYPE-C" mdxType="Image" />
USB Type-Cはコネクタの形状及び、ピンの役割を定義した規格である。ピンとは端子内にある入出力を受け付ける口のことで、USB Type-C端子には合計で24のピンが存在する。このピン同士がケーブルで繋がれることによりデータや電力のやり取りがなされるわけである。
    <p>{`Type-Cの要件として全てのピンをそれぞれ等しい用途で利用することは定められておらず、`}<strong parentName="p"><em parentName="strong">{`使用するピンやその用途はケーブルによってある程度自由に決めることができる`}</em></strong>{`。`}</p>
    <p>{`この結果USB Type-Cのケーブルによって機能や値段に差が出てくるわけである。`}</p>
    <h3 {...{
      "id": "リバーシブル形状で耐久性が大幅に向上",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%AA%E3%83%90%E3%83%BC%E3%82%B7%E3%83%96%E3%83%AB%E5%BD%A2%E7%8A%B6%E3%81%A7%E8%80%90%E4%B9%85%E6%80%A7%E3%81%8C%E5%A4%A7%E5%B9%85%E3%81%AB%E5%90%91%E4%B8%8A",
        "aria-label": "リバーシブル形状で耐久性が大幅に向上 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`リバーシブル形状で耐久性が大幅に向上`}</h3>
    <Image {...props} name="usb_type_A_B_C_s" alt="USB TYPE A、TYPE B、TYPE C" mdxType="Image" />
    <p>{`形状は上下対象となっており、端子を挿し込こもうとする時になかなか挿さらず、よく見たところ反対を向いていたことが判明して端子の向きを変えて挿し直すという誰でも経験する地味な面倒くささから開放してくれる。`}</p>
    <p>{`また、耐久性も従来のTypeA/TypeBと比べて大幅に向上している。TypeA/Bでは1500回程度の端子の抜き差しにおける耐久性(挿抜耐久性)しか担保されておらず、頻繁にケーブルの不良が起こっていた。しかし`}<strong parentName="p">{`Type-Cは10000回以上の挿抜耐久性が認証試験で求められており、非常にタフな端子`}</strong>{`となっている。よって長い間使った場合でも故障率は低い。`}</p>
    <h2 {...{
      "id": "USB-Type-Cの伝送速度は対応USB-VersionとGenerationに依存",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#USB-Type-C%E3%81%AE%E4%BC%9D%E9%80%81%E9%80%9F%E5%BA%A6%E3%81%AF%E5%AF%BE%E5%BF%9CUSB-Version%E3%81%A8Generation%E3%81%AB%E4%BE%9D%E5%AD%98",
        "aria-label": "USB Type Cの伝送速度は対応USB VersionとGenerationに依存 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB Type-Cの伝送速度は対応USB VersionとGenerationに依存`}</h2>
    <p>{`次にUSB Type-Cの伝送速度について解説したい。使用するピンやその用途によりケーブルの性質が異なるということを前節で書いたが、Type-Cはケーブルやパソコンにより対応するVersionが異なってくる。`}</p>
    <h3 {...{
      "id": "最低はUSB-20準拠の480Mbps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%9C%80%E4%BD%8E%E3%81%AFUSB-20%E6%BA%96%E6%8B%A0%E3%81%AE480Mbps",
        "aria-label": "最低はUSB 20準拠の480Mbps permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`最低はUSB 2.0準拠の480Mbps`}</h3>
    <p>{`24本あるピンのうち２本がUSB2.0専用の信号線に使われ、8本がSuperSpeed信号線というUSB3.0以上で定義される信号線で使われる。このうちSuperSpeed信号線がごそっと無いType-Cケーブルが販売されており、このケーブルではUSB2.0準拠の480Mbpsが伝送速度となる。`}</p>
    <p><strong parentName="p">{`Type-CだからUSB2.0よりも速いということは保証されていない`}</strong>{`のである。`}</p>
    <h3 {...{
      "id": "USB30準拠で5Gbps、31で10Gbps、40で40Gbpsまで対応",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#USB30%E6%BA%96%E6%8B%A0%E3%81%A75Gbps%E3%80%8131%E3%81%A710Gbps%E3%80%8140%E3%81%A740Gbps%E3%81%BE%E3%81%A7%E5%AF%BE%E5%BF%9C",
        "aria-label": "USB30準拠で5Gbps、31で10Gbps、40で40Gbpsまで対応 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB3.0準拠で5Gbps、3.1で10Gbps、4.0で40Gbpsまで対応`}</h3>
    <p>{`以下USBの対応Versionが上がるごとにUSB3.0では5Gbps、3.1で10Gbps、3.2では20Gbpsまで対応できる。`}</p>
    <p>{`ただ、USB3.1では２つの転送モードがサポートされており、それぞれUSB3.1 Gen1、USB3.2 Gen2と区別される。USB3.1 Gen1はUSB3.0と同様の5Gbps、USB3.1 Gen2が10Gbpsの転送速度である。`}</p>
    <p>{`ここで注意としては`}<strong parentName="p">{`USB3.1対応でもGen2の転送がサポートされていない場合がある`}</strong>{`ことである。この場合USB3.1対応を謳っていても転送速度は5Gbpsとなる。`}</p>
    <p>{`USB3.2もUSB 3.2 Gen1、Gen2、Gen2x2の３種類の規格が存在し、それぞれ5Gbps/10Gbps/20Gbpsの転送速度となっている。
しかし`}<strong parentName="p">{`同様にすべてのUSB3.2がGen2x2をサポートしているわけではないため注意が必要`}</strong>{`である。`}</p>
    <p>{`さらにその上でUSB 4.0があり、こちらは40Gbpsまで対応。`}<strong parentName="p"><em parentName="strong">{`感覚としては500GBある標準的なパソコン内のすべてのデータを3分程度で全て伝送できるスピード`}</em></strong>{`である。`}</p>
    <h3 {...{
      "id": "USB規格、転送モード、速度まとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#USB%E8%A6%8F%E6%A0%BC%E3%80%81%E8%BB%A2%E9%80%81%E3%83%A2%E3%83%BC%E3%83%89%E3%80%81%E9%80%9F%E5%BA%A6%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "USB規格、転送モード、速度まとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB規格、転送モード、速度まとめ`}</h3>
    <p>{`以上をまとめると下の表のようになる。見ると分かるのだが、USB3.0とUSB3.1 Gen1とUSB3.2 Gen1はそれぞれ全く同じ規格である。同じ規格に対して呼び名が様々であるので購入時には注意しよう。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th>規格名</th>
      <th>転送モード(ブランド名)</th>
      <th>転送速度</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>USB2.0</td>
      <td>High-Speed</td>
      <td>480Mbps</td>
    </tr>
    <tr>
      <td>USB3.0</td>
      <td>SuperSpeed</td>
      <td>5Gbps</td>
    </tr>
    <tr>
      <td>USB3.1 Gen1</td>
      <td>SuperSpeed</td>
      <td>5Gbps</td>
    </tr>
    <tr>
      <td>USB3.1 Gen2</td>
      <td>SuperSpeed+</td>
      <td>10Gbps</td>
    </tr>
    <tr>
      <td>USB3.2 Gen1</td>
      <td>SuperSpeed USB</td>
      <td>5Gbps</td>
    </tr>
    <tr>
      <td>USB3.2 Gen2</td>
      <td>SuperSpeed USB 10Gbps</td>
      <td>10Gbps</td>
    </tr>
    <tr>
      <td>USB3.2 Gen2x2</td>
      <td>SuperSpeed USB 20Gbps</td>
      <td>20Gbps</td>
    </tr>
    <tr>
      <td>USB4</td>
      <td>USB4 40Gbps</td>
      <td>40Gbps</td>
    </tr>
  </tbody>
    </BasicTable>
    <h2 {...{
      "id": "USB-Type-Cの電力供給能力",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#USB-Type-C%E3%81%AE%E9%9B%BB%E5%8A%9B%E4%BE%9B%E7%B5%A6%E8%83%BD%E5%8A%9B",
        "aria-label": "USB Type Cの電力供給能力 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB Type-Cの電力供給能力`}</h2>
    <p>{`最初にUSBの電力供給の基本を説明する。USBから提供される電力をUSBバスパワーと呼び、電力供給を受けてマウスやキーボードを動作させたり、携帯の充電を行うことができる。`}</p>
    <p><strong parentName="p">{`基本的な電力は2.5W(5V/500mA)でこれはUSB標準電力と呼ばれる`}</strong>{`こともある。USB3.0ではこの電力が4.5W(5V/900mA)まで引き上げられている。`}</p>
    <p>{`ところが急速充電を行うため、これより大きな電力を供給する仕組みをUSBは備えている。Type-CのPowerDeliveryも含めそれぞれ解説する。`}</p>
    <h3 {...{
      "id": "USB-BCBattery-Charge",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#USB-BCBattery-Charge",
        "aria-label": "USB BCBattery Charge permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB BC(Battery Charge)`}</h3>
    <p>{`まずは急速充電規格として基本となるUSB BCである。これは格安なものも含めほぼすべてのUSB充電器に搭載されており、`}<strong parentName="p"><em parentName="strong">{`敢えてUSB BC対応を打ち出している製品は無い`}</em></strong>{`。`}</p>
    <p>{`この規格では最大7.5W(5V/1.5W)までの電力を供給できる。`}</p>
    <p>{`ところでパソコンのUSBポートからの充電が遅いと感じた人はいないだろうか？これはパソコンのUSBポートはUSB2.0で2.5W(USB3.0で4.5W)までの電力供給能力しかないからである。`}</p>
    <h3 {...{
      "id": "USB-Type-C-Currentで15Wまで出力",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#USB-Type-C-Current%E3%81%A715W%E3%81%BE%E3%81%A7%E5%87%BA%E5%8A%9B",
        "aria-label": "USB Type C Currentで15Wまで出力 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB Type-C Currentで15Wまで出力`}</h3>
    <p>{`ようやくUSB Type-Cの話題となるが、USB Type-CではUSBバスパワーが大幅に強化され最大15Wまでを出力できるようになった。この電力供給を特にUSB Type-C Currentと呼んでいる。`}</p>
    <p>{`具体的には２つのモードが存在し、一つ目は`}<strong parentName="p"><em parentName="strong">{`USB Type-C `}<a parentName="em" {...{
            "href": "mailto:Current@1.5A",
            "target": "_blank",
            "rel": "nofollow noopener"
          }}>{`Current@1.5A`}</a>{`という7.tW(5V/1.5A)の電力を供給する`}</em></strong>{`モードで、２つ目が`}<strong parentName="p"><em parentName="strong">{`USB Type-C Current@3Aという15W(5V/3W)の電力を供給するモード`}</em></strong>{`である。`}</p>
    <p>{`ともあれ、これによりパソコンからの電力供給でUSB BCに対応しているACアダプタを上回ることができ、`}<strong parentName="p">{`パソコンからは充電が遅いと感じることはなくなるはず`}</strong>{`である。`}</p>
    <p>{`注意点として、USB Type-Cに接続されているものの、`}<strong parentName="p"><em parentName="strong">{`途中USB TypeBなどに変換している場合は従来通り(2.5W~4.5W)までの出力しかできない`}</em></strong>{`点が挙げられる。`}</p>
    <p>{`15Wは2.5Wの6倍の電力量に当たり、かなりおもしろい事ができそうである。例えばUSB扇風機はより風が強い送風ができるだろうし、`}<strong parentName="p"><em parentName="strong">{`ペットボトルやマグカップを保温するUSB接続器も今後普及しそう`}</em></strong>{`である。`}</p>
    <h3 {...{
      "id": "USB-PowerDeliveryPDによる急速充電",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#USB-PowerDeliveryPD%E3%81%AB%E3%82%88%E3%82%8B%E6%80%A5%E9%80%9F%E5%85%85%E9%9B%BB",
        "aria-label": "USB PowerDeliveryPDによる急速充電 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB PowerDelivery(PD)による急速充電`}</h3>
    <p>{`これはパソコンからの電力供給よりも主にコンセントからノートパソコンへの電力供給の話となる。`}</p>
    <p>{`USB BCより速く充電を行える規格は乱立状態である。たとえばクアルコム社のQuick ChargeはSnapdragonというチップを搭載したスマホに対して充電を行え、最大出力は18Wまで(Quick Charge v3の場合)サポートされる。大抵のAndroid端末はSnapdragonを搭載していることから広く普及が進んでいる。`}</p>
    <p>{`また、HUAWEI(ファーウェイ)はSuperCharge 40Wという10V/4Aまでの出力する規格を独自に制定している。`}</p>
    <p>{`しかし、`}<strong parentName="p">{`USB Type-Cの大容量電力供給規格であるPowerDelivery`}</strong>{`によりこの状況は変わってきつつあり、特にiPhone8(2017年発売)がUSB PDをサポートして以降に普及が進んでいる。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`PowerDeliveryは最大100W(20V/5A)まで出力することができる規格`}</em></strong>{`であり、それぞれケーブルと接続機器が対応していれば急速充電が可能となる。`}</p>
    <p>{`充電時に100Wを供給できるわけではなく、`}<strong parentName="p"><em parentName="strong">{`電子機器側と電力供給側での内部的なやりとりを通じ、どこまで電力供給が可能であるかを決定する`}</em></strong>{`。このやりとりにより、給電能力は電圧(5V/9V/15V/20V)と電流(3A/5A)の組み合わせにより決定される。`}</p>
    <p>{`ノートパソコンだと45W程度の充電が可能な機器が多いイメージである。`}</p>
    <h3 {...{
      "id": "パソコンからUSB-PDで電力供給することは無い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%8B%E3%82%89USB-PD%E3%81%A7%E9%9B%BB%E5%8A%9B%E4%BE%9B%E7%B5%A6%E3%81%99%E3%82%8B%E3%81%93%E3%81%A8%E3%81%AF%E7%84%A1%E3%81%84",
        "aria-label": "パソコンからUSB PDで電力供給することは無い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`パソコンからUSB PDで電力供給することは無い`}</h3>
    <p>{`パソコンのUSB Type-C端子から100Wを出力可能というような機器はいまだ発売されていない。基本的にコンセントから電力が供給されることをUSB PDでは想定されており、パソコンからの電力供給は15WのUSB Type-C Current@3Aが現状最大という状況である。`}</p>
    <h3 {...{
      "id": "電力供給能力まとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E9%9B%BB%E5%8A%9B%E4%BE%9B%E7%B5%A6%E8%83%BD%E5%8A%9B%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "電力供給能力まとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`電力供給能力まとめ`}</h3>
    <p>{`最後に上記の電力供給能力をまとめて表にしてみる。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th>規格名</th>
      <th>最大供給電力</th>
      <th>備考</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>USB2.0</td>
      <td>2.5W(5V/500mA)</td>
      <td>USB標準電力</td>
    </tr>
    <tr>
      <td>USB3.0</td>
      <td>4.5W(5V/900mA)</td>
      <td></td>
    </tr>
    <tr>
      <td>USB BC(Battery Charge)</td>
      <td>7.5W(5V/1.5A)</td>
      <td>ACアダプタからの給電</td>
    </tr>
    <tr>
      <td>USB Type-C Current</td>
      <td>15W(5V/3A)</td>
      <td>7.5W/15Wの２つのモードがある</td>
    </tr>
    <tr>
      <td>USB PB(PowerDelivery)</td>
      <td>100W(20V/5A)</td>
      <td>ACアダプタから。5,9,15,20Vの電圧と3,5Aの組み合わせ</td>
    </tr>
  </tbody>
    </BasicTable>
    <h2 {...{
      "id": "USB-PDでノートパソコンの駆動が可能",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#USB-PD%E3%81%A7%E3%83%8E%E3%83%BC%E3%83%88%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E9%A7%86%E5%8B%95%E3%81%8C%E5%8F%AF%E8%83%BD",
        "aria-label": "USB PDでノートパソコンの駆動が可能 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB PDでノートパソコンの駆動が可能`}</h2>
    <p>{`充電について話してきたが、急速充電で使われる電力はせいぜいが20W程度である。これはスマートホンが熱に耐えられない、危険性、安定性の問題など様々な理由があると思われる。`}</p>
    <p>{`では、それ以上の電力を何に使うのかと言われたときに`}<strong parentName="p">{`最も想定されているケースがノートパソコンの駆動及び充電`}</strong>{`である。グラフィックボードを搭載しているゲーム用のノートパソコンの駆動はPDでは難しいが、MacBookなど大抵のノートパソコンは45W程度の電力供給で駆動することができる。`}</p>
    <p>{`今後は鬱陶しいACアダプタを持ち歩く必要はなく、USB Type-Cの電源のみを持ち運べばスマホもパソコンも両方充電ができ、便利になることは間違いない。`}</p>
    <h3 {...{
      "id": "USB-PDの数珠繋ぎが可能",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#USB-PD%E3%81%AE%E6%95%B0%E7%8F%A0%E7%B9%8B%E3%81%8E%E3%81%8C%E5%8F%AF%E8%83%BD",
        "aria-label": "USB PDの数珠繋ぎが可能 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB PDの数珠繋ぎが可能`}</h3>
    <p>{`USBドック(USBハブ)という複数のUSB端子を増設する機器を繋ぐときにこの数珠繋ぎの機能は重宝する。電源アダプタからハブへ電力供給を行い、そのハブ経由でUSB Type-Cケーブルを用いてノートパソコンなどへ電力供給ができる。ノートパソコンにマウスやキーボード、プリンタ、USBメモリなど複数の周辺機器を接続したい場合で同時に充電や駆動を行いたい場合には重要な機能となる。`}</p>
    <Image {...props} name="pd_chain" alt="USB PDによる電力供給" mdxType="Image" />
    <h2 {...{
      "id": "USB-Type-Cの映像伝送能力",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#USB-Type-C%E3%81%AE%E6%98%A0%E5%83%8F%E4%BC%9D%E9%80%81%E8%83%BD%E5%8A%9B",
        "aria-label": "USB Type Cの映像伝送能力 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB Type-Cの映像伝送能力`}</h2>
    <p>{`USB Type-Cは映像や音声をモニタへ向けて送信することが可能である。ただし、全てのUSB Type-C機器、ケーブルがこれに対応しているわけではなく、オルタネートモードに対応したものだけが可能となる。`}</p>
    <p>{`オルタネートモード(Alternate Mode、Altモードとも)とは、Type-Cの24個のピンのうち高速データ伝送を行う8つのピンに対して、別のプロトコルで動作させることが可能なモードである。映像伝送を行う時にはこのオルタネートモードを利用する。`}</p>
    <p>{`代表的なモードとしてはThunderbolt 4とDisplayPortがあるのでそれぞれ説明していく。`}</p>
    <h3 {...{
      "id": "Thunderbolt-4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Thunderbolt-4",
        "aria-label": "Thunderbolt 4 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Thunderbolt 4`}</h3>
    <p>{`Thunderbolt 4は`}<strong parentName="p">{`USB4の40Gbpsでデータ転送可能でかつ40Gbpsまでの映像出力を可能にするインターフェース`}</strong>{`である。`}</p>
    <p>{`具体的にはThunderbolt 4規格に準拠している場合は下記が保証される。`}</p>
    <AccentBox title="Thunderbolt 4で可能なこと" mdxType="AccentBox">
  <li><span className="bold">USB4</span>・・・40Gbpsでのデータ転送が可能</li>
  <li><span className="bold">DisplayPort 1.4</span>・・・4K@120Hzのディスプレイの出力が可能。4Kディスプレイ2台接続も可能</li>
  <li><span className="bold">USB PD</span>・・・100W(5V/20W)までの電力供給</li>
    </AccentBox>
    <p>{`見ると分かるが、USB Type-Cで可能なことがほぼ全て備わっており、`}<strong parentName="p"><em parentName="strong">{`Thunderbolt 4に対応していさえすれば取り合えずType-Cの機能で書かれていることは何でも可能`}</em></strong>{`と考えても問題がない。`}</p>
    <p><strong parentName="p">{`合計で4K60Hzで２画面(あるいは5K60Hz1枚)まで対応する`}</strong>{`のが映像伝送能力の凄さといえる。具体的には`}<a parentName="p" {...{
        "href": "https://amzn.to/4e9uEjE",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`thunderbolt 4 ドッキングステーション`}</a>{`などを使い、２画面出力をするのが一般的である。`}</p>
    <h3 {...{
      "id": "DisplayPort",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#DisplayPort",
        "aria-label": "DisplayPort permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`DisplayPort`}</h3>
    <p>{`Thunderbolt 4対応のケーブルも含めて高価である。およそ3000円程度はする。より安価にしたい場合DisplayPort Over USB Type-CというDisplayPortのオルタネートモードにのみ対応したケーブルが販売されている。製品としては`}<a parentName="p" {...{
        "href": "https://amzn.to/4eUSlxf",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`usb type-c displayport ケーブル`}</a>{`で調べればよい。電力供給などの機能はないが、割安で購入することができる。`}</p>
    <h2 {...{
      "id": "ノートパソコンからデュアルモニタ環境を瞬時に構築",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%8E%E3%83%BC%E3%83%88%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%8B%E3%82%89%E3%83%87%E3%83%A5%E3%82%A2%E3%83%AB%E3%83%A2%E3%83%8B%E3%82%BF%E7%92%B0%E5%A2%83%E3%82%92%E7%9E%AC%E6%99%82%E3%81%AB%E6%A7%8B%E7%AF%89",
        "aria-label": "ノートパソコンからデュアルモニタ環境を瞬時に構築 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ノートパソコンからデュアルモニタ環境を瞬時に構築`}</h2>
    <p>{`Type-Cの機能を活用すると次の図ように簡単にノートパソコンからデスクトップのデュアルモニタ環境を作り上げることができる。`}</p>
    <Image {...props} name="pc_display" caption="Type-Cを利用したデュアルモニタ環境の構築" mdxType="Image" />
    <p>{`デュアルモニタにすると仕事効率が上がるため、家ではリッチなディスプレイ環境で作業を行いたいという人に適している。家に帰ってディスプレイから生えているケーブルを一本挿せばよいだけなのでシームレスに作業が継続できる。ここには重要な技術が含まれておりそれらについて解説する。`}</p>
    <h3 {...{
      "id": "映像伝送と電力供給がUSB-Type-C一本で可能",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%98%A0%E5%83%8F%E4%BC%9D%E9%80%81%E3%81%A8%E9%9B%BB%E5%8A%9B%E4%BE%9B%E7%B5%A6%E3%81%8CUSB-Type-C%E4%B8%80%E6%9C%AC%E3%81%A7%E5%8F%AF%E8%83%BD",
        "aria-label": "映像伝送と電力供給がUSB Type C一本で可能 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`映像伝送と電力供給がUSB Type-C一本で可能`}</h3>
    <p>{`まず、注目すべき点は映像出力とPD電力供給を同時に実現していることである。１本のType-Cで両方行えているため、わざわざ`}<strong parentName="p">{`ACアダプタを挿してノートパソコンを充電する必要が無い`}</strong>{`。
これは電力供給のソース/シンクと映像出力におけるホスト/デバイスの方向をそれぞれ制御できる`}<strong parentName="p">{`デュアルロール`}</strong>{`という制御機能のおかげで成り立っている。`}</p>
    <h3 {...{
      "id": "デイジーチェーンによるディスプレイの数珠繋ぎ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%87%E3%82%A4%E3%82%B8%E3%83%BC%E3%83%81%E3%82%A7%E3%83%BC%E3%83%B3%E3%81%AB%E3%82%88%E3%82%8B%E3%83%87%E3%82%A3%E3%82%B9%E3%83%97%E3%83%AC%E3%82%A4%E3%81%AE%E6%95%B0%E7%8F%A0%E7%B9%8B%E3%81%8E",
        "aria-label": "デイジーチェーンによるディスプレイの数珠繋ぎ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`デイジーチェーンによるディスプレイの数珠繋ぎ`}</h3>
    <p>{`またノートパソコン及びケーブルがDisplayPortのオルタネートモードに対応していてかつ、ディスプレイにデイジーチェーン用のDisplayPortが存在する場合、モニタを数珠繋ぎで連結することができ、簡単にデュアルモニタ環境を構築することができる。`}</p>
    <h3 {...{
      "id": "モニタのUSBハブとしての機能",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%A2%E3%83%8B%E3%82%BF%E3%81%AEUSB%E3%83%8F%E3%83%96%E3%81%A8%E3%81%97%E3%81%A6%E3%81%AE%E6%A9%9F%E8%83%BD",
        "aria-label": "モニタのUSBハブとしての機能 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`モニタのUSBハブとしての機能`}</h3>
    <p>{`Type-Cでのディスプレイ出力を行えるモニタには大抵USBポートがいくつか備え付けられている。この場合モニタをUSBドック(USBハブ)として使うことができる。`}</p>
    <p>{`プリンタやキーボードなどを優先で繋いでも良いし、無線通信用のアダプタを挿してワイヤレスマウスを使えるようにしても良い。`}</p>
    <p>{`ポイントとしては`}<strong parentName="p">{`モニタがあるだけで、周辺機器を含めて環境を構築してくれるということ`}</strong>{`である。持ち運び用にノート、家ではデスクトップというように使い分けている人はこの方法を試してみるのはありかもしれない。`}</p>
    <h3 {...{
      "id": "USB-Type-C対応は高価だが普及している",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#USB-Type-C%E5%AF%BE%E5%BF%9C%E3%81%AF%E9%AB%98%E4%BE%A1%E3%81%A0%E3%81%8C%E6%99%AE%E5%8F%8A%E3%81%97%E3%81%A6%E3%81%84%E3%82%8B",
        "aria-label": "USB Type C対応は高価だが普及している permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB Type-C対応は高価だが普及している`}</h3>
    <p>{`最後にこれらの機能を用いるためにはパソコンがThunderboltの機能を備えるとともに、`}<a parentName="p" {...{
        "href": "https://amzn.to/3NPx5gg",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`USB Type-C対応モニタ`}</a>{`が必要となる。USB Type-Cモニタは急速に普及をはじめ、今では多くの製品が発売されているため、価格はやや高価であるが気軽に試すことはできる。`}</p>
    <h2 {...{
      "id": "その他Type-Cでおすすめの機能、注意点",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%9D%E3%81%AE%E4%BB%96Type-C%E3%81%A7%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E3%81%AE%E6%A9%9F%E8%83%BD%E3%80%81%E6%B3%A8%E6%84%8F%E7%82%B9",
        "aria-label": "その他Type Cでおすすめの機能、注意点 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`その他Type-Cでおすすめの機能、注意点`}</h2>
    <p>{`最後に他に知っておいた方が良さそうなことを紹介する。`}</p>
    <h3 {...{
      "id": "外付けのグラフィックボードにThunderboltで対応",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%A4%96%E4%BB%98%E3%81%91%E3%81%AE%E3%82%B0%E3%83%A9%E3%83%95%E3%82%A3%E3%83%83%E3%82%AF%E3%83%9C%E3%83%BC%E3%83%89%E3%81%ABThunderbolt%E3%81%A7%E5%AF%BE%E5%BF%9C",
        "aria-label": "外付けのグラフィックボードにThunderboltで対応 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`外付けのグラフィックボードにThunderboltで対応`}</h3>
    <p>{`ゲーミングのノートパソコンは重量が軽量のものでも2kgはあり、非常に重い。よって持ち歩くにはちょっと不便である。しかし`}<a parentName="p" {...{
        "href": "https://amzn.to/3NM9SMc",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`Thunderboltの高速伝送を用いた外付けのGPUボックス(eGPU)`}</a>{`が発売されている。`}</p>
    <p>{`ノートパソコンを外付けGPUに繋ぎ、GPUが映像をレンダリングし、その結果をノートパソコンの画面、あるいは外部ディスプレイへ出力してくれるのである。`}</p>
    <p>{`ある程度ノートパソコンにCPUのスペックが無いと、CPUがボトルネックとなり処理速度が落ちることはあるのでハイスペックなノートと組み合わせて使うのが良い。`}</p>
    <p>{`ともあれこのボックスを使いGPUを装着することにより、`}<strong parentName="p"><em parentName="strong">{`軽量のノートパソコン一台で本格的な3Dゲームを実行`}</em></strong>{`することができる。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      